const static_stock_nav = {

    // شاخص ها
    index: {
        overview: {
            title: 'در یک نگاه',
            name: 'StockProfileOverview',
            icon: 'uil uil-layer-group',
            hide: false,
        },
        history: {
            title: 'شاخص در روز جاری',
            name: 'StockProfileHistory',
            icon: 'uil uil-arrow-growth',
            hide: false,
        },
        technical: {
            title: 'تاریخچه',
            name: 'StockProfileTechnical',
            icon: 'uil uil-calendar-alt',
            hide: false,
        },
        indicator: {
            title: 'عملکرد',
            name: 'StockProfileIndicator',
            icon: 'uil uil-comparison',
            hide: false,
        },
        charts: {
            title: 'تکنیکال',
            name: 'StockProfileCharts',
            icon: 'uil uil-chart',
            hide: false,
        },
    },
    // سهام
    stock: {
        overview: {
            title: 'در یک نگاه',
            name: 'StockProfileOverview',
            icon: 'uil uil-layer-group',
            hide: false,
        },
        history: {
            title: 'تاریخچه',
            name: 'StockProfileHistory',
            icon: 'uil uil-arrow-growth',
            hide: false,
        },
        technical: {
            title: 'تکنیکال',
            name: 'StockProfileTechnical',
            icon: 'uil uil-calendar-alt',
            hide: false,
        },
        indicator: {
            title: 'اندیکاتورها',
            name: 'StockProfileIndicator',
            icon: 'uil uil-comparison',
            hide: false,
        },
        charts: {
            title: 'نمودارها',
            name: 'StockProfileCharts',
            icon: 'uil uil-chart',
            hide: true,
        },
        news: {
            title: 'اطلاعیه‌ها',
            name: 'StockProfileNews',
            icon: 'uil uil-chart',
            hide: true,
        },
        eps: {
            title: 'EPS',
            name: 'StockProfileEps',
            icon: 'uil uil-chart',
            hide: false,
        },
        capital: {
            title: 'افزایش سرمایه',
            name: 'StockProfileCapital',
            icon: 'uil uil-chart',
            hide: false,
        },
        balancesheet: {
            title: 'ترازنامه',
            name: 'StockProfileBalancesheet',
            icon: 'uil uil-comparison',
            hide: false,
        },
        profitloss: {
            title: 'سود و زیان',
            name: 'StockProfileProfitloss',
            icon: 'uil uil-chart',
            hide: false,
        },
        cashflow: {
            title: 'گردش وجوه نقد',
            name: 'StockProfileCashflow',
            icon: 'uil uil-chart',
            hide: true,
        },
        financialratios: {
            title: 'نسبت‌های مالی',
            name: 'StockProfileFinancialratios',
            icon: 'uil uil-chart',
            hide: true,
        },
        category: {
            title: 'نمادهای هم‌گروه',
            name: 'StockProfileCategory',
            icon: 'uil uil-chart',
            hide: true,
        },
        specification: {
            title: 'مشخصات',
            name: 'StockProfileSpecification',
            icon: 'uil uil-chart',
            hide: true,
        },
    },
    // حق تقدم
    // like stock
    warrant: {
        overview: {
            title: 'در یک نگاه',
            name: 'StockProfileOverview',
            icon: 'uil uil-layer-group',
            hide: false,
        },
        history: {
            title: 'تاریخچه',
            name: 'StockProfileHistory',
            icon: 'uil uil-arrow-growth',
            hide: false,
        },
        technical: {
            title: 'تکنیکال',
            name: 'StockProfileTechnical',
            icon: 'uil uil-calendar-alt',
            hide: false,
        },
        indicator: {
            title: 'اندیکاتورها',
            name: 'StockProfileIndicator',
            icon: 'uil uil-comparison',
            hide: false,
        },
        charts: {
            title: 'نمودارها',
            name: 'StockProfileCharts',
            icon: 'uil uil-chart',
            hide: true,
        },
        news: {
            title: 'اطلاعیه‌ها',
            name: 'StockProfileNews',
            icon: 'uil uil-chart',
            hide: true,
        },
        eps: {
            title: 'EPS',
            name: 'StockProfileEps',
            icon: 'uil uil-chart',
            hide: false,
        },
        capital: {
            title: 'افزایش سرمایه',
            name: 'StockProfileCapital',
            icon: 'uil uil-chart',
            hide: false,
        },
        balancesheet: {
            title: 'ترازنامه',
            name: 'StockProfileBalancesheet',
            icon: 'uil uil-comparison',
            hide: false,
        },
        profitloss: {
            title: 'سود و زیان',
            name: 'StockProfileProfitloss',
            icon: 'uil uil-chart',
            hide: false,
        },
        cashflow: {
            title: 'گردش وجوه نقد',
            name: 'StockProfileCashflow',
            icon: 'uil uil-chart',
            hide: true,
        },
        financialratios: {
            title: 'نسبت‌های مالی',
            name: 'StockProfileFinancialratios',
            icon: 'uil uil-chart',
            hide: true,
        },
        category: {
            title: 'نمادهای هم‌گروه',
            name: 'StockProfileCategory',
            icon: 'uil uil-chart',
            hide: true,
        },
        specification: {
            title: 'مشخصات',
            name: 'StockProfileSpecification',
            icon: 'uil uil-chart',
            hide: true,
        },
    },
    // نمادهای متوقف شده
    // like stock
    closedassets: {
        overview: {
            title: 'در یک نگاه',
            name: 'StockProfileOverview',
            icon: 'uil uil-layer-group',
            hide: false,
        },
        history: {
            title: 'تاریخچه',
            name: 'StockProfileHistory',
            icon: 'uil uil-arrow-growth',
            hide: false,
        },
        technical: {
            title: 'تکنیکال',
            name: 'StockProfileTechnical',
            icon: 'uil uil-calendar-alt',
            hide: false,
        },
        indicator: {
            title: 'اندیکاتورها',
            name: 'StockProfileIndicator',
            icon: 'uil uil-comparison',
            hide: false,
        },
        charts: {
            title: 'نمودارها',
            name: 'StockProfileCharts',
            icon: 'uil uil-chart',
            hide: true,
        },
        news: {
            title: 'اطلاعیه‌ها',
            name: 'StockProfileNews',
            icon: 'uil uil-chart',
            hide: true,
        },
        eps: {
            title: 'EPS',
            name: 'StockProfileEps',
            icon: 'uil uil-chart',
            hide: false,
        },
        capital: {
            title: 'افزایش سرمایه',
            name: 'StockProfileCapital',
            icon: 'uil uil-chart',
            hide: false,
        },
        balancesheet: {
            title: 'ترازنامه',
            name: 'StockProfileBalancesheet',
            icon: 'uil uil-comparison',
            hide: false,
        },
        profitloss: {
            title: 'سود و زیان',
            name: 'StockProfileProfitloss',
            icon: 'uil uil-chart',
            hide: false,
        },
        cashflow: {
            title: 'گردش وجوه نقد',
            name: 'StockProfileCashflow',
            icon: 'uil uil-chart',
            hide: true,
        },
        financialratios: {
            title: 'نسبت‌های مالی',
            name: 'StockProfileFinancialratios',
            icon: 'uil uil-chart',
            hide: true,
        },
        category: {
            title: 'نمادهای هم‌گروه',
            name: 'StockProfileCategory',
            icon: 'uil uil-chart',
            hide: true,
        },
        specification: {
            title: 'مشخصات',
            name: 'StockProfileSpecification',
            icon: 'uil uil-chart',
            hide: true,
        },
    },
    // اوراق
    bond: {
        overview: {
            title: 'در یک نگاه',
            name: 'StockProfileOverview',
            icon: 'uil uil-layer-group',
            hide: false,
        },
        history: {
            title: 'تاریخچه',
            name: 'StockProfileHistory',
            icon: 'uil uil-arrow-growth',
            hide: false,
        },
        technical: {
            title: 'تکنیکال',
            name: 'StockProfileTechnical',
            icon: 'uil uil-calendar-alt',
            hide: false,
        },
        indicator: {
            title: 'اندیکاتورها',
            name: 'StockProfileIndicator',
            icon: 'uil uil-comparison',
            hide: false,
        },
        charts: {
            title: 'نمودارها',
            name: 'StockProfileCharts',
            icon: 'uil uil-chart',
            hide: true,
        },
        news: {
            title: 'اطلاعیه‌ها',
            name: 'StockProfileNews',
            icon: 'uil uil-chart',
            hide: true,
        },
        category: {
            title: 'نمادهای هم‌گروه',
            name: 'StockProfileCategory',
            icon: 'uil uil-chart',
            hide: true,
        },
        specification: {
            title: 'مشخصات',
            name: 'StockProfileSpecification',
            icon: 'uil uil-chart',
            hide: true,
        },
    },
    // تسهیلات مسکن
    // like bond
    mortgageloan: {
        overview: {
            title: 'در یک نگاه',
            name: 'StockProfileOverview',
            icon: 'uil uil-layer-group',
            hide: false,
        },
        history: {
            title: 'تاریخچه',
            name: 'StockProfileHistory',
            icon: 'uil uil-arrow-growth',
            hide: false,
        },
        technical: {
            title: 'تکنیکال',
            name: 'StockProfileTechnical',
            icon: 'uil uil-calendar-alt',
            hide: false,
        },
        indicator: {
            title: 'اندیکاتورها',
            name: 'StockProfileIndicator',
            icon: 'uil uil-comparison',
            hide: false,
        },
        charts: {
            title: 'نمودارها',
            name: 'StockProfileCharts',
            icon: 'uil uil-chart',
            hide: true,
        },
        news: {
            title: 'اطلاعیه‌ها',
            name: 'StockProfileNews',
            icon: 'uil uil-chart',
            hide: true,
        },
        category: {
            title: 'نمادهای هم‌گروه',
            name: 'StockProfileCategory',
            icon: 'uil uil-chart',
            hide: true,
        },
        specification: {
            title: 'مشخصات',
            name: 'StockProfileSpecification',
            icon: 'uil uil-chart',
            hide: true,
        },
    },
    // آتی
    // like bond
    future: {
        overview: {
            title: 'در یک نگاه',
            name: 'StockProfileOverview',
            icon: 'uil uil-layer-group',
            hide: false,
        },
        history: {
            title: 'تاریخچه',
            name: 'StockProfileHistory',
            icon: 'uil uil-arrow-growth',
            hide: false,
        },
        technical: {
            title: 'تکنیکال',
            name: 'StockProfileTechnical',
            icon: 'uil uil-calendar-alt',
            hide: false,
        },
        indicator: {
            title: 'اندیکاتورها',
            name: 'StockProfileIndicator',
            icon: 'uil uil-comparison',
            hide: false,
        },
        charts: {
            title: 'نمودارها',
            name: 'StockProfileCharts',
            icon: 'uil uil-chart',
            hide: true,
        },
        news: {
            title: 'اطلاعیه‌ها',
            name: 'StockProfileNews',
            icon: 'uil uil-chart',
            hide: true,
        },
        category: {
            title: 'نمادهای هم‌گروه',
            name: 'StockProfileCategory',
            icon: 'uil uil-chart',
            hide: true,
        },
        specification: {
            title: 'مشخصات',
            name: 'StockProfileSpecification',
            icon: 'uil uil-chart',
            hide: true,
        },
    },
    // اختیار معامله یا آپشن
    // like bond
    option: {
        overview: {
            title: 'در یک نگاه',
            name: 'StockProfileOverview',
            icon: 'uil uil-layer-group',
            hide: false,
        },
        history: {
            title: 'تاریخچه',
            name: 'StockProfileHistory',
            icon: 'uil uil-arrow-growth',
            hide: false,
        },
        technical: {
            title: 'تکنیکال',
            name: 'StockProfileTechnical',
            icon: 'uil uil-calendar-alt',
            hide: false,
        },
        indicator: {
            title: 'اندیکاتورها',
            name: 'StockProfileIndicator',
            icon: 'uil uil-comparison',
            hide: false,
        },
        charts: {
            title: 'نمودارها',
            name: 'StockProfileCharts',
            icon: 'uil uil-chart',
            hide: true,
        },
        news: {
            title: 'اطلاعیه‌ها',
            name: 'StockProfileNews',
            icon: 'uil uil-chart',
            hide: true,
        },
        category: {
            title: 'نمادهای هم‌گروه',
            name: 'StockProfileCategory',
            icon: 'uil uil-chart',
            hide: true,
        },
        specification: {
            title: 'مشخصات',
            name: 'StockProfileSpecification',
            icon: 'uil uil-chart',
            hide: true,
        },
    },
    // صندوق‌ها
    fund: {
        overview: {
            title: 'در یک نگاه',
            name: 'StockProfileOverview',
            icon: 'uil uil-layer-group',
            hide: false,
        },
        history: {
            title: 'تاریخچه',
            name: 'StockProfileHistory',
            icon: 'uil uil-arrow-growth',
            hide: false,
        },
        technical: {
            title: 'تکنیکال',
            name: 'StockProfileTechnical',
            icon: 'uil uil-calendar-alt',
            hide: false,
        },
        indicator: {
            title: 'اندیکاتورها',
            name: 'StockProfileIndicator',
            icon: 'uil uil-comparison',
            hide: false,
        },
        charts: {
            title: 'نمودارها',
            name: 'StockProfileCharts',
            icon: 'uil uil-chart',
            hide: true,
        },
        news: {
            title: 'اطلاعیه‌ها',
            name: 'StockProfileNews',
            icon: 'uil uil-chart',
            hide: true,
        },
        category: {
            title: 'نمادهای هم‌گروه',
            name: 'StockProfileCategory',
            icon: 'uil uil-chart',
            hide: true,
        },
        specification: {
            title: 'مشخصات',
            name: 'StockProfileSpecification',
            icon: 'uil uil-chart',
            hide: true,
        },
        nav: {
            title: 'روند NAV',
            name: 'StockProfileNav',
            icon: 'uil uil-chart',
            hide: true,
        },
    },
};

export default static_stock_nav;