<template> 
<div class="" v-if=" stock_header_info && stock_header_info.market != 'index'">
    <div class="market-profile-mobile-pages">
        <div class="market-symbol-row" v-if="stock_header_info">
            <div class="market-symbol-row-right">
                <div class="market-symbol-avatar" :style="avatarFinder"></div>
                <div class="market-symbol-info">
                    <h1 class="market-symbol-title">{{ stock_header_info.title }}</h1>
                    <span class="market-symbol-title-en">
                        {{ stock_header_info.slug }} |
                        {{ stock_header_info.market_fa }}
                        <span class="market-symbol-market">{{ stock_header_info.flow.title}}</span>
                    </span>
                </div>
            </div>
            <div class="market-symbol-row-left">
                <div class="market-symbol-info">
                    <div class="market-symbol-price">
                        <span class="price" data-col="info.last_trade.PDrCotVal">{{ stock_header_info.last_trade.PDrCotVal }}</span>
                    </div>
                    <span class="market-symbol-change">
                        <span class="change-tag" data-col="info.last_trade.last_change_percentage" :data-val="stock_header_info.last_trade.last_change_percentage">
                            <span>{{ stock_header_info.last_trade.last_change_percentage }}</span>
                        </span>
                    </span>
                </div>
            </div>
        </div>
        <div class="market-symbol-row" v-else>
            <div class="text-center p-2" style="flex-grow: 1;">
                <div class="widget-loading"></div>
            </div>
        </div>
    </div>
    <div class="market-slider-tabs-box" style="border-top: 1px solid #ececec;">
        <div class="mobile-back-btn" v-on:click="back()">
            <i class="uil uil-angle-right-b"></i>
        </div>
        <div class="market-slider-tabs">
            <template>
                <v-card>
                    <v-tabs >
                        <v-tabs-slider color="teal lighten-3"></v-tabs-slider>
                        <v-tab v-for="(item, index) in nav_bar" :key="index" v-if="!item.hide" :to="{name: item.name, params: { name: $route.params.name }, }">
                            <i :class="item.icon"></i>
                            <span>{{ item.title }}</span>
                        </v-tab>
                    </v-tabs>
                </v-card>
            </template>
        </div>
    </div>
</div>
<div v-else>
    <div class="market-profile-mobile-pages">
        <div class="market-symbol-row" v-if="stock_header_info">
            <div class="market-symbol-row-right">
                <div class="market-symbol-avatar" :style="avatarFinder"></div>
                <div class="market-symbol-info">
                    <h1 class="market-symbol-title">{{ stock_header_info.title }}</h1>
                    <span class="market-symbol-title-en">
                        {{ stock_header_info.slug }} |
                        {{ stock_header_info.market_fa }}
                        <span class="market-symbol-market">{{ stock_header_info.flow.title}}</span>
                    </span>
                </div>
            </div>
            <div class="market-symbol-row-left">
                <div class="market-symbol-info">
                    <div class="market-symbol-price">
                        <span class="price" data-col="info.last_trade.PDrCotVal">{{ stock_header_info.value }}</span>
                    </div>
                    <span class="market-symbol-change">
                        <!-- <span class="change-tag" data-col="info.last_trade.last_change_percentage" :data-val="stock_header_info.last_trade.last_change_percentage">
                            <span>{{ stock_header_info.last_trade.last_change_percentage }}</span>
                        </span> -->
                    </span>
                </div>
            </div>
        </div>
        <div class="market-symbol-row" v-else>
            <div class="text-center p-2" style="flex-grow: 1;">
                <div class="widget-loading"></div>
            </div>
        </div>
    </div>
    <div class="market-slider-tabs-box" style="border-top: 1px solid #ececec;">
        <div class="mobile-back-btn" v-on:click="back()">
            <i class="uil uil-angle-right-b"></i>
        </div>
        <div class="market-slider-tabs">
            <template>
                <v-card>
                    <v-tabs >
                        <v-tabs-slider color="teal lighten-3"></v-tabs-slider>
                        <v-tab v-for="(item, index) in nav_bar" :key="index" v-if="!item.hide" :to="{name: item.name, params: { name: $route.params.name }, }">
                            <i :class="item.icon"></i>
                            <span>{{ item.title }}</span>
                        </v-tab>
                    </v-tabs>
                </v-card>
            </template>
        </div>
    </div>
</div>

</template>
<style>
    .tgju-widget-content.full-padding{
        height: auto !important;
    }
    .widgets .tgju-widget.has-icon {
        overflow: hidden;
        border-radius: 0 !important;
    }
    body.profile .header-items-p .line .title {
        font-size: 18px !important;
    }
    .market-main-sub-tabs {
        padding: 12px 10px 15px;
    }
    html body.profile .col-12 {
        padding: 0 5px !important;
    }
    .widgets .dataTables_wrapper .dataTables_paginate .paginate_button {
        padding: 10px 20px;
        border-radius: 6px !important;
    }
    html body.profile .widgets .tgju-widgets-row .tgju-widgets-block {
        margin-bottom: 5px;
    }
    .chart-element {
        position: relative;
        top: -10px;
    }
    html .widgets .tgju-widget .tgju-widget-title.dot::before {
        background: #eec62a !important;
    }
    .widgets .dataTables_wrapper {
        overflow: hidden;
    }
    .widgets .tgju-widget .tables-data .dataTables_wrapper {
        padding-bottom: 20px;
    }
    .dashboard-dev.dashboard-main.sidebar-set-full.bootstrap-fix.widgets {
        padding: 0 0 10px !important;
    }
    .dashboard-dev.dashboard-main.sidebar-set-full.bootstrap-fix.widgets {
        overflow-x: hidden;
    }
</style>

<script>
import stockNavPage from "@/assets/static_stock_nav_page";
export default {
    name: 'StockHeader',
    props: ['data', 'type', 'name'],
    data() {
        return {
            //this.data.info
            stock_header_info: null,
            nav_bar: stockNavPage[this.$route.params.type],
        }
    },
    mounted() {
        if (this.$store.state.stockHeader && this.$store.state.stockHeader.name == this.name) {
            this.stock_header_info = this.$store.state.stockHeader.info;
        } else {
            if (this.data) {
                this.stock_header_info = this.data.info;
                this.$store.commit('setStockHeader', {
                    name : this.name,
                    info : this.stock_header_info,
                    // summary : this.market_header_summary
                });
            } else {
                this.getProfileHeader();
            }
        }
    },
    computed: {
        // آواتار یک شاخص را پیدا می کند
        avatarFinder: function () {
            if (this.stock_header_info.avatar) {
                return  {
                    'position' : 'relative',
                    'background-image': 'url(' + this.stock_header_info.avatar + ')',
                }
            } else if ( this.stock_header_info.country_code && this.stock_header_info.country_code != 'wd'){
                return  {
                    'position' : 'relative',
                    'background-size': '140%',
                    'background-image': 'url(https://www.tgju.org/images/flags/4x3/' + this.stock_header_info.country_code + '.svg)',
                }
            }

            return  {
                'position' : 'relative',
                'background-image': 'url(https://static.tgju.org/images/no-icon.png)',
            }
        },
    },
    methods: {
        
        getProfileHeader() {
            this.$helpers
                .makeRequest("GET", `/stock/profile-header/${this.name}`)
                .then((api_response) => {
                    if (api_response.status == 200) {
                        this.stock_header_info = api_response.data.response.detail.body.response.info;
                        this.$store.commit('setStockHeader', {
                            name : this.name,
                            info : this.stock_header_info,
                            // summary : this.market_header_summary
                        });
                        $('.stocks-profile').append('<link rel="stylesheet" type="text/css" href="https://static.tgju.org/components/tgju-fix/fix-custom.css">');
                        $('body').addClass('internal profile fs-grid mprofile');
                    }
                });
        },
        // دکمه بازگشت
        back() {
            this.$router.go(-1);
        },
    },
}
</script>