
<template>
    <div class="tv-iframe-wrapper" style="position: absolute;top: 120px;left: 0px;right: 0;bottom: 0;width: 100%;overflow: hidden;"> 
        <iframe id="tv-iframe" :src="`https://www.tgju.org/panel/tv4/?symbol=${ name }`" style="border-color: rgb(216, 219, 221) currentcolor currentcolor; border-style: solid none none; border-width: 1px 0px 0px; border-image: none 100% / 1 / 0 stretch;  display: inline-block;  min-height: unset !important; overflow: hidden;position: absolute;right: 0px;left: 0;top: 0;bottom: 0;height: 100% !important;width: 100%;"></iframe>
    </div>
</template>

<script>
export default {
    name: 'StockTechnical',
    props: ['name'],
    data() {
        return {
        }
    },
    mounted() {
    },
    methods: {
        // این متد جهت نمایش بهتر نرخ ها و قیمت ها می باشد // جدا کننده قیمت با کاما
        formatPrice(value) {
            if (!value) {
                // this.hide_demands = true;
                return '-';
            }
            return value.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
        },
        intToString(value) {
            if ((value).length > 12) {
                return 'هزار میلیارد';
            } else if ((value).length > 9) {
                return 'میلیارد';
            } else if ((value).length > 6) {
                return 'میلیون';
            } else if ((value).length > 3) {
                return '';
            } else {
                return '';
            }
        },
        roundInt(value) {
            if ((value).length > 12) {
                return parseFloat(parseFloat(value / Math.pow(1000,4)).toFixed(3));
            } else if ((value).length > 9) {
                return parseFloat(parseFloat(value / Math.pow(1000,3)).toFixed(3));
            } else if ((value).length > 6) {
                return parseFloat(parseFloat(value / Math.pow(1000,2)).toFixed(3));
            } else if ((value).length > 3) {
                return this.formatPrice(value);
            } else {
                return value;
            }
        },
        setActive(index){
            if(index == this.activeLi){
                this.activeLi = null;
            }else{
                this.activeLi = index;
            }
        },
    },
}
</script>